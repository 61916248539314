$(document).ready(function ()
{

	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN
	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});

	$('.btn-load-more').click(function(){
		var list = $(this).attr('data-for');
		$list = $('[data-list="'+list+'"]');

		var itemsperpage = parseInt($list.attr('data-itemsperpage'));
		var page = $list.attr('data-page');
		var startindex = parseInt((page*itemsperpage)-1);
		var endindex = startindex+itemsperpage;

		if ($list.find('> div').size() < endindex) {
			$(this).hide();
		}

		$list.find('> div').slice(startindex,endindex).fadeIn();
		page++;
		$list.attr('data-page', page);
	});

	$('.choose-pack').fancybox({
		//'width'				: '50%',
		//'height'			: '85%',
		'maxWidth'			: '100%',
		'maxHeight'			: '100%',
		'autoScale'     	: false,
		'transitionIn'		: 'none',
		'transitionOut'		: 'none',
		'type'				: 'iframe',
		'padding'			: 0,
		'autoSize'			: false,
		'fitToView'			: false,
	});



	/*
	 *	SEARCH BOX
	 */

	 $('.search-toggler').click(function(e){
	 	if ($('.header').hasClass('search-open')) {
	 		$('.header').removeClass('search-open');

	 		$('.search-toggler').html('<i class="icon-search"></i>');
	 	} else {
	 		$('.header').addClass('search-open');

	 		$('.search-form input').focus();
	 		$('.search-toggler').html('<i class="icon-angle-up"></i>');
	 	}
	 });

	/*
	 *	REFERENCES
	 */

	 $('#references-slider').flexslider({
	 	animation: "slide",
	 	controlNav: false,
	 	animationLoop: false,
	 	slideshow: false,
	 	itemWidth: 168,
	 	itemMargin: 20,
	 	prevText: '<i class="icon-angle-left"></i>',
	 	nextText: '<i class="icon-angle-right"></i>'
	 });

	/*
	 *	SCROLL REVEAL
	 */
	 window.sr = ScrollReveal({ reset: false });
	 sr.reveal('.sr');
	 sr.reveal('.sr-left', {origin: 'left', scale: 1, viewOffset: {right: -100}});
	 sr.reveal('.sr-right', {origin: 'right', scale: 1, viewOffset: {right: -100}});
	 sr.reveal('.sr-top', {origin: 'top', scale: 1, viewOffset: {right: -50}});
	 sr.reveal('.gradient-title', {origin: 'left', scale: 1, viewOffset: {left: -50}});
	});

	/*
	 *	SHARE DIALOGS
	 */
	function social_share_button(medium, urlToOpen, width, height) {
		if (typeof ga !== 'undefined') {
			ga('send', 'event', 'Social Sharing', medium, 'pagina');
		}
		if ( urlToOpen != '' )
		{
			window.open( urlToOpen, medium, "height="+height+",width="+width+",resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0" )
		}
	}

/*
 *	SCROLL CHECK - HEADER
 */

var timer;
var triggerHeight = 10;

$(window).scroll(function() {
 	if(timer) {
		window.clearTimeout(timer);
	}

 	timer = window.setTimeout(function() {
		// this variable changes between callbacks, so we can't cache it
		var y = $(window).scrollTop();

		if(y > triggerHeight) {
			$('.header').addClass('header--border');
		} else {
			$('.header').removeClass('header--border');
		}

	}, 10);
 });



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}